// Import our CSS
import "@/scss/glightbox.scss";

import GLightbox from 'glightbox';

var lightboxInlineIframe = GLightbox({
    selector: '.glightbox'
});

const experienceFormLightbox = GLightbox({
    closeButton: true,
    selector: null,
    skin: "experience-form-wrapper glightbox-clean",
    width: "100%",
});

let connectedContentLightboxes = document.querySelectorAll(".g-content");
let connectedContentLightboxesPopups = {};
connectedContentLightboxes.forEach(element => {
    const trigger = element.dataset.triggerclass;
    if(typeof(connectedContentLightboxesPopups[trigger]) === "undefined") {
        connectedContentLightboxesPopups[trigger] = GLightbox({
            closeButton: true,
            selector: '.' + trigger,
            skin: "experience-form-wrapper js-more-height glightbox-clean",
            width: "100%",
        });
    }
});

window.runGlightBoxAPI = function(element) {
    let apiForm = element.getAttribute("data-apiForm");
    element.addEventListener('click', function(e) {
        e.preventDefault();
        let paragraphText = element.querySelector(".p-txt");
        let paragraphLoader = element.querySelector(".p-loader");

        if (paragraphText) {
            paragraphText.style.display = "none";
        }

        if (paragraphLoader) {
            paragraphLoader.style.display = "inline-block";
        }
        element.setAttribute("disabled", "");

        let url = "/apis/" + apiForm + "?";
        if(element.getAttribute("data-event")) {
            url = url + "event=" + element.getAttribute("data-event") + "&";
        }

        if(element.getAttribute("data-form")) {
            url = url + "form=" + element.getAttribute("data-form") + "&";
        }

        if(element.getAttribute("data-selected")) {
            url = url + "selected=" + element.getAttribute("data-selected") + "&";
        }

        if(element.getAttribute("data-watch")) {
            url = url + "field=" + element.getAttribute("data-watch") + "&";
        }

        if(element.getAttribute("data-category")) {
            url = url + "category=" + element.getAttribute("data-category") + "&";
        }

        if(element.getAttribute("data-tribe")) {
            url = url + "tribe=" + element.getAttribute("data-tribe") + "&";
        }

        if(element.getAttribute("data-date")) {
            url = url + "date=" + element.getAttribute("data-date") + "&";
        }

        fetch(url, {
            method: "GET",
            cache: "no-cache",
            redirect: "follow",
            credentials: "same-origin",
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            },
        })
        .then((response) => response.text())
        .then((data) => {
            // console.log(data);
            let replaceContent = '';
            if(typeof(element.dataset.heading) !== "undefined" && element.dataset.heading.trim()) {
                replaceContent += `<h3 class="text-2xl md:text-3.5xl font-bold mb-3">${element.dataset.heading}</h3>`;
            }

            if(typeof(element.dataset.subheading) !== "undefined" && element.dataset.subheading.trim()) {
                replaceContent += `<h4 class="text-xl font-bold mb-3">${element.dataset.subheading}</h4>`;
            }

            if(typeof(element.dataset.text) !== "undefined" && element.dataset.text.trim()) {
                replaceContent += `<div class="mb-3">${element.dataset.text}</div>`;
            }

            experienceFormLightbox.setElements([{
                content: replaceContent + data,
                width: typeof(element.dataset.width) !== "undefined" ? element.dataset.width : "100%",
                height: typeof(element.dataset.height) !== "undefined" ? element.dataset.height : "100vh"
            }]);
            experienceFormLightbox.open();

            setTimeout(function() {
                if (element.getAttribute('data-form') != 'gallery-form') {
                    window.Formie.initForms();
                }

                if (paragraphText) {
                    paragraphText.style.display = "inline-block";
                }

                if (paragraphLoader) {
                    paragraphLoader.style.display = "none";
                }
            }, 50);
        });
    });
}

let experienceForms = document.querySelectorAll(".glightbox-popup-form");
experienceForms.forEach(element => {
    runGlightBoxAPI(element);
});
// lightboxInlineIframe.reload();
